import React from "react";
// import PreviewCompatibleImage from './PreviewCompatibleImage';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Column, Columns } from "../components/bulma";

export default function Blurbs({ box, items }) {
  return (
    <Columns>
      {items?.map(item => (
        <div key={item.title} className="column">
          <Column>
            {/* <Box> */}
            <div className="notification">
              {/* <FontAwesomeIcon icon="store" size="10x" className="has-text-light" /> */}
              {/* <div className="has-text-centered">
									<div
										style={{
											width: '240px',
											display: 'inline-block'
										}}
									>
										<PreviewCompatibleImage imageInfo={item} />
									</div>
								</div> */}
              <br />
              <p className="title is-size-3 is-spaced">{item.title}</p>
              <p className="subtitle is-size-5">{item.text}</p>
            </div>
            {/* </Box> */}
          </Column>
        </div>
      ))}
    </Columns>
  );
}
